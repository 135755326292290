import React, { useContext } from 'react'
import { Select, MenuItem, useTheme, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { store } from './store'

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export default function LanguageMenu() {
  const { i18n } = useTranslation()
  const theme = useTheme()
  const query = useQuery()
  const { state, dispatch } = useContext(store)

  React.useEffect(() => {
    const language = query.get('lang')

    if (!language && state.indonesiaDomain) {
      i18n.changeLanguage('id')
    }

    if (language) {
      i18n.changeLanguage(language)
    }
  }, [])

  return (
    <Select
      value={i18n.language}
      defaultValue={i18n.language}
      size="small"
      autoWidth
      sx={{ background: theme.palette.common.white }}
      renderValue={(val) => (
        <img width="25" src={`/images/common/flags/${val}.svg`} />
      )}
      onChange={(e) => i18n.changeLanguage(e.target.value)}
    >
      <MenuItem value="bg">
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <div>
            <img width="25" src="/images/common/flags/bg.svg" />
          </div>
          <div>
            <Typography>Bulgarian</Typography>
          </div>
        </Stack>
      </MenuItem>
      <MenuItem value="de">
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <div>
            <img width="25" src="/images/common/flags/de.svg" />
          </div>
          <div>
            <Typography>German</Typography>
          </div>
        </Stack>
      </MenuItem>
      <MenuItem value="en">
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <div>
            <img width="25" src="/images/common/flags/en.svg" />
          </div>
          <div>
            <Typography>English</Typography>
          </div>
        </Stack>
      </MenuItem>
      <MenuItem value="es">
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <div>
            <img width="25" src="/images/common/flags/es.svg" />
          </div>
          <div>
            <Typography>Spanish</Typography>
          </div>
        </Stack>
      </MenuItem>
      <MenuItem value="fr">
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <div>
            <img width="25" src="/images/common/flags/fr.svg" />
          </div>
          <div>
            <Typography>French Canadian</Typography>
          </div>
        </Stack>
      </MenuItem>
      <MenuItem value="hu">
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <div>
            <img width="25" src="/images/common/flags/hu.svg" />
          </div>
          <div>
            <Typography>Hungarian</Typography>
          </div>
        </Stack>
      </MenuItem>
      <MenuItem value="id">
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <div>
            <img width="25" src="/images/common/flags/id.svg" />
          </div>
          <div>
            <Typography>Bahasa</Typography>
          </div>
        </Stack>
      </MenuItem>
      <MenuItem value="jp">
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <div>
            <img width="25" src="/images/common/flags/jp.svg" />
          </div>
          <div>
            <Typography>Japanese</Typography>
          </div>
        </Stack>
      </MenuItem>
      <MenuItem value="ru">
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <div>
            <img width="25" src="/images/common/flags/ru.svg" />
          </div>
          <div>
            <Typography>Russian</Typography>
          </div>
        </Stack>
      </MenuItem>
    </Select>
  )
}
