import React from 'react'
import ReactDOM from 'react-dom'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { StateProvider } from './components/store'
import './i18n/config'
import './style.css'
import theme from './theme'
import reportWebVitals from './reportWebVitals'

// React page components
import Signup from './pages/Signup'
import ReviewPurchase from './pages/ReviewPurchase'
import Payment from './pages/Payment'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <StateProvider>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <BrowserRouter>
            <Routes>
              <Route index element={<Signup />} />
              <Route path="review" element={<ReviewPurchase />} />
              <Route path="payment" element={<Payment />} />
              <Route path=":placement/:sponsorId/" element={<Signup />} />
              <Route
                path=":placement/:sponsorId/:placementId"
                element={<Signup />}
              />
              <Route path=":sponsorId" element={<Signup />} />
            </Routes>
          </BrowserRouter>
        </LocalizationProvider>
      </StateProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
