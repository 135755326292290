import format from 'format-number'
import axios from 'axios'
import qs from 'qs'

export const usCurrency = (num: number) =>
  `${new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }).format(num)} USD`

export const indonesiaCurrency = (num: number) =>
  new Intl.NumberFormat('en-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
  }).format(num)

export function debounce(fn: (...params: any) => void, threshold = 750) {
  let timeout: any
  return function cb(...args: any) {
    clearTimeout(timeout)
    // @ts-ignore
    timeout = setTimeout(() => fn.apply(this, args), threshold)
  }
}

export function getUserAge(dateString: string) {
  const today = new Date()
  const birthDate = new Date(dateString)
  const month = today.getMonth() - birthDate.getMonth()
  let age = today.getFullYear() - birthDate.getFullYear()

  // if their birthday technically makes then younger than their birth year would indicate
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }

  return age
}

export function padWithZero(text: any) {
  if (!text) {
    return ''
  }

  return `${text}`.padStart(2, '0')
}

export const arrayOfStates = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
]

export const gdprCountries = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SL',
  'ES',
  'SE',
  'GB',
]

export const getPackageInfoFromId = (id: number) => {
  switch (id) {
    case 1:
      return {
        id: 1,
        name: 'Video Email',
        fee: 99,
        monthlyPlan: 10,
        qty: 1,
        bv: 40,
      }
    case 2:
      return {
        id: 2,
        name: 'Video Suite',
        indonesiaName: 'Executive',
        fee: 249,
        indonesiaFeeAssociate: 289,
        indonesiaFeeCustomer: 289 * 1.1,
        monthlyPlan: 25,
        indonesiaMonthlyPlan: 50,
        qty: 1,
        bv: 100,
      }
    case 3:
      return {
        id: 3,
        name: 'Elite',
        fee: 750,
        indonesiaFeeAssociate: 789,
        indonesiaFeeCustomer: 789 * 1.1,
        monthlyPlan: 50,
        indonesiaMonthlyPlan: 50,
        qty: 1,
        bv: 200,
      }
    case 4:
      return {
        id: 4,
        name: 'Pro',
        fee: 1499,
        indonesiaFeeAssociate: 1539,
        indonesiaFeeCustomer: 1539 * 1.1,
        monthlyPlan: 50,
        indonesiaMonthlyPlan: 50,
        qty: 1,
        bv: 600,
      }
    default:
      return {
        id: 0,
        name: '',
        fee: 0,
        monthlyPlan: 0,
        indonesiaFee: 0,
        qty: 1,
        bv: 0,
      }
  }
}

const getItemCode = (currentState: any) => {
  const { packageInfo } = currentState
  let itemCodeList = ''
  let planItemCode = 'VEO'

  switch (packageInfo.id) {
    case 1:
      itemCodeList = 'VEP'
      break
    case 2:
    case 3:
      itemCodeList = 'VSP'
      planItemCode = 'BAS'
      break
    case 4:
      itemCodeList = 'PROP'
      planItemCode = 'BAS'
      break
  }

  switch (currentState.prePayDiscount) {
    case 10:
      planItemCode += '1'
      break
    case 15:
      planItemCode += '3'
      break
    case 20:
      planItemCode += '5'
      break
  }

  return `${itemCodeList}|${planItemCode}`
}

export const usdToIdr = (amount: number) => {
  return amount * 15500
}

export const IdrToUsd = (amount: number) => {
  return amount * 0.0000646
}

export const getSalesTax = async (currentState: any) => {
  const { packageInfo } = currentState
  const packagePrice = currentState.indonesiaDomain
    ? packageInfo.indonesiaFee || packageInfo.fee
    : packageInfo.fee
  const doubleDigit = format({ padRight: 2 })
  const { data }: any = await axios({
    url: 'https://signup.talkfusion.com/order/join/_get_my_salestax_v12.asp',
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
    data: qs.stringify({
      memberid: '1341931',
      orderId: currentState.order.orderId,
      itemCode: getItemCode(currentState),
      amount: `${doubleDigit(packagePrice + packageInfo.monthlyPlan, {
        noSeparator: true,
      })}|${doubleDigit(packageInfo.monthlyPlan, { noSeparator: true })}`,
      description: '',
      commitOrder: 0,
    }),
  })

  return data
}
