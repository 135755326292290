import React, { useContext } from 'react'
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'
import Scroll from 'react-scroll'
import { useTranslation } from 'react-i18next'
import { store } from '../../components/store'

export default function Registration() {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const { state, dispatch } = useContext(store)
  const Element = Scroll.Element
  const scroller = Scroll.scroller

  const handlePackageSelect = async (id: number) => {
    await dispatch({ type: 'SET_PLAN_ID', payload: id })
    scroller.scrollTo('registration', {
      duration: 1500,
      smooth: true,
    })
  }

  return (
    <Box
      sx={{
        textAlign: 'center',
        py: 8,
        backgroundColor: theme.palette.grey[200],
      }}
    >
      <Element name="packages"></Element>
      <Typography variant="h3" sx={{ fontWeight: 100 }}>
        {t('SIGNUP_STEP2_CHOOSE_PRODUCT_PACKAGE_TITLE')}
      </Typography>
      <Typography
        component="div"
        dangerouslySetInnerHTML={{
          __html: t('SIGNUP_STEP2_CHOOSE_PRODUCT_PACKAGE_BODY'),
        }}
      ></Typography>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          py: 8,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent sx={{ px: 0 }}>
                {(() => {
                  switch (true) {
                    case state.isIndonesia && state.registration.program === 0:
                      return (
                        <img
                          src={`/images/common/idr-new-product-packages/executive/id-customer.svg`}
                        />
                      )
                    case state.isIndonesia && state.registration.program === 1:
                      return (
                        <img
                          src={`/images/common/idr-new-product-packages/executive/id-associate.svg`}
                        />
                      )
                    case !state.isIndonesia:
                    default:
                      return (
                        <img
                          src={`/images/common/product-packages/video-email/${i18n.language}.svg`}
                        />
                      )
                  }
                })()}
              </CardContent>
              <Box
                sx={{
                  mx: 'auto',
                  py: 2,
                  backgroundColor: theme.palette.grey[300],
                }}
              >
                <Button
                  onClick={() => handlePackageSelect(state.isIndonesia ? 2 : 1)}
                  variant="contained"
                  size="large"
                  sx={{ px: 8 }}
                >
                  {(!state.indonesiaDomain &&
                    state.registration.planId === 1) ||
                  (state.isIndonesia && state.registration.planId === 2)
                    ? 'Selected'
                    : t('SELECT')}
                </Button>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} sx={{ position: 'relative' }}>
            {state.country !== 'ID' && (
              <Box sx={{ position: 'absolute', top: -25, right: -25 }}>
                <img
                  width="100"
                  src="/images/common/product-packages/EN-30Day.svg"
                />
              </Box>
            )}
            <Card>
              <CardContent sx={{ px: 0 }}>
                {(() => {
                  switch (true) {
                    case state.isIndonesia && state.registration.program === 0:
                      return (
                        <img
                          src={`/images/common/idr-new-product-packages/elite/id-customer.svg`}
                        />
                      )
                    case state.isIndonesia && state.registration.program === 1:
                      return (
                        <img
                          src={`/images/common/idr-new-product-packages/elite/id-associate.svg`}
                        />
                      )
                    case !state.isIndonesia:
                    default:
                      return (
                        <img
                          src={`/images/common/product-packages/video-suite/${i18n.language}.svg`}
                        />
                      )
                  }
                })()}
              </CardContent>
              <Box
                sx={{
                  mx: 'auto',
                  py: 2,
                  backgroundColor: theme.palette.grey[300],
                }}
              >
                <Button
                  onClick={() => handlePackageSelect(state.isIndonesia ? 3 : 2)}
                  variant="contained"
                  size="large"
                  sx={{ px: 8 }}
                >
                  {(!state.indonesiaDomain &&
                    state.registration.planId === 2) ||
                  (state.isIndonesia && state.registration.planId === 3)
                    ? 'Selected'
                    : t('SELECT')}
                </Button>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} sx={{ position: 'relative' }}>
            <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
              <img
                width="100"
                src={`/images/common/product-packages/flags/${i18n.language}.svg`}
              />
            </Box>
            <Card>
              <CardContent sx={{ px: 0 }}>
                {(() => {
                  switch (true) {
                    case state.isIndonesia && state.registration.program === 0:
                      return (
                        <img
                          src={`/images/common/idr-new-product-packages/pro/id-customer.svg`}
                        />
                      )
                    case state.isIndonesia && state.registration.program === 1:
                      return (
                        <img
                          src={`/images/common/idr-new-product-packages/pro/id-associate.svg`}
                        />
                      )
                    case !state.isIndonesia:
                    default:
                      return (
                        <img
                          src={`/images/common/product-packages/pro/${i18n.language}.svg`}
                        />
                      )
                  }
                })()}
              </CardContent>
              <Box
                sx={{
                  mx: 'auto',
                  py: 2,
                  backgroundColor: theme.palette.grey[300],
                }}
              >
                <Button
                  onClick={() => handlePackageSelect(4)}
                  variant="contained"
                  size="large"
                  sx={{ px: 8 }}
                >
                  {state.registration.planId === 4 ? 'Selected' : t('SELECT')}
                </Button>
              </Box>
            </Card>
          </Grid>
        </Grid>
        {state.country !== 'ZA' && (
          <Box sx={{ mt: 8 }}>
            <Typography>
              {t('SIGNUP_STEP2_CHOOSE_PRODUCT_PACKAGE_NOT_INTERESTED')}
            </Typography>
            <Box sx={{ mb: 6 }}>
              <Button onClick={() => handlePackageSelect(0)} variant="text">
                {t('CLICK_HERE')}
              </Button>
            </Box>
            <Typography variant="caption" sx={{ pt: 8 }}>
              <em>{t('SIGNUP_STEP2_CHOOSE_PRODUCT_PACKAGE_ASTERISK_NOTE')}</em>
            </Typography>
          </Box>
        )}
      </Container>
    </Box>
  )
}
