import React, { useContext, useState, useEffect } from 'react'
import Container from '@mui/material/Container'
import {
  Alert,
  Box,
  Button,
  Card,
  Snackbar,
  Typography,
  TextField,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  useTheme,
  Divider,
} from '@mui/material'
import { useParams } from 'react-router-dom'
import Scroll from 'react-scroll'
import { useTranslation } from 'react-i18next'
import LanguageMenu from '../../components/LanguageMenu'
import NoSponsorModal from '../../components/dialogs/NoSponsorModal'
import { Countries } from '../../lib/countries'
import axios from '../../lib/axios'
import { store } from '../../components/store'

const style = {
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat no-repeat',
  backgroundImage:
    'url("https://jointalkfusion.com/images/signup-background.jpg")',
}

export default function Sponsorship() {
  const { t, i18n } = useTranslation()
  const [open, toggleOpen] = useState(false)
  const [sponsorId, setSponsorId] = useState('')
  const [toast, setToast] = useState(false)
  const { state, dispatch } = useContext(store)
  const theme = useTheme()
  const params: any = useParams()
  const scroller = Scroll.scroller

  const getSponsorInfo = async (id?: string) => {
    if (id) {
      try {
        const {
          data: { error, result },
        }: any = await axios.get(`/sign-up/get-sponsor-info?sponsorId=${id}`)

        if (!error) {
          dispatch({ type: 'SET_SPONSOR', payload: result })
        } else {
          setToast(true)
        }
      } catch {
        setToast(true)
      }
    }
  }

  const handleCountryChange = async (e: any) => {
    await dispatch({ type: 'SET_COUNTRY', payload: e.target.value })

    if (!state.isIndonesia && e.target.value !== 'ID') {
      scroller.scrollTo('packages', {
        duration: 1500,
        smooth: true,
      })
    } else {
      dispatch({ type: 'CLEAR_PROGRAM_TYPE' })
    }
  }

  useEffect(() => {
    if (params.sponsorId) {
      setSponsorId(params.sponsorId)
      getSponsorInfo(params.sponsorId)
    } else if (window.location.hostname.split('.').length > 2) {
      const subdomain = window.location.hostname.substring(
        0,
        window.location.hostname.indexOf('.')
      )

      setSponsorId(subdomain)
      getSponsorInfo(subdomain)
    }

    if (params.placement) {
      dispatch({ type: 'SET_PLACEMENT', payload: params.placement })
    }

    if (params.placementId) {
      dispatch({ type: 'SET_PLACEMENT_ID', payload: params.placementId })
    }
  }, [params.sponsorId])

  const onIndonesianCustomerTypeChange = async (e: any) => {
    await dispatch({ type: 'SET_PROGRAM_TYPE', payload: e.target.value })
    scroller.scrollTo('packages', {
      duration: 1500,
      smooth: true,
    })
  }

  return (
    <Box style={style} sx={{ height: '100%' }}>
      {/* Modal */}
      <NoSponsorModal open={open} close={() => toggleOpen(false)} />

      {/* Snackbar Notification */}
      <Snackbar
        open={toast}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setToast(false)}
      >
        <Alert severity="error">
          <Typography>Sponsor ID is invalid</Typography>
        </Alert>
      </Snackbar>

      {/* Page Container */}
      <Container
        maxWidth="sm"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            right: 12,
            top: 12,
          }}
        >
          <LanguageMenu />
        </Box>

        {/* Getting started card */}
        <Box sx={{ position: 'relative' }}>
          <Card
            sx={{
              padding: 4,
            }}
          >
            <Typography variant="h4">
              {t('SIGNUP_STEP1_HEADER_BODY')}
            </Typography>
            {state.sponsor && (
              <Box>
                <Alert severity="success" sx={{ my: 2 }}>
                  {t('SIGNUP_STEP2_HEADER_SUBTITLE')}
                </Alert>
                <Typography align="center">{state.sponsor.name}</Typography>
                <Typography align="center">
                  {state.sponsor.accountNumber ||
                    state.sponsor.account?.accountNumber}
                </Typography>
                <TextField
                  select
                  value={state.country}
                  fullWidth
                  label={t('COUNTRY')}
                  sx={{ mt: 2 }}
                  onChange={handleCountryChange}
                >
                  {Countries.map((c) => (
                    <MenuItem value={c.value} key={c.name}>
                      {c.name}
                    </MenuItem>
                  ))}
                </TextField>
                {state.isIndonesia && (
                  <Box
                    sx={{
                      border: `1px solid ${theme.palette.divider}`,
                      mt: 2,
                      borderRadius: 1,
                    }}
                  >
                    <RadioGroup onChange={onIndonesianCustomerTypeChange}>
                      <FormControlLabel
                        value="0"
                        label={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: t(
                                'SIGNUP_STEP2_RADIO_TALKFUSION_CUSTOMER'
                              ),
                            }}
                          ></div>
                        }
                        control={<Radio />}
                        sx={{ p: 2 }}
                      />
                      <Divider sx={{ my: 1 }} />
                      <FormControlLabel
                        value="1"
                        label={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: t(
                                'SIGNUP_STEP2_RADIO_TALKFUSION_ASSOCIATE'
                              ),
                            }}
                          ></div>
                        }
                        control={<Radio />}
                        sx={{ p: 2 }}
                      />
                    </RadioGroup>
                  </Box>
                )}
              </Box>
            )}
            {!state.sponsor && (
              <Box>
                <TextField
                  fullWidth
                  required
                  defaultValue=""
                  label={t('SPONSOR_ID')}
                  inputProps={{ maxLength: 7 }}
                  onChange={(e: any) => setSponsorId(e.target.value)}
                  onKeyUp={(e) =>
                    e.key === 'Enter' && getSponsorInfo(sponsorId)
                  }
                  sx={{ my: 2 }}
                />
                <Typography sx={{ fontSize: 13, opacity: 0.8 }}>
                  {t(
                    'SIGNUP_STEP1_ASSOCIATE_VERIFICATION_TEXTFIELD_PLACEHOLDER'
                  )}
                </Typography>
                <Box
                  sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Button
                    onClick={() => toggleOpen(true)}
                    variant="outlined"
                    sx={{ mr: 2 }}
                  >
                    {t('SIGNUP_STEP1_NOSPONSOR_ID_BUTTON')}
                  </Button>
                  <Button
                    onClick={() => getSponsorInfo(sponsorId)}
                    disabled={!sponsorId || !/\d{7}/.test(sponsorId)}
                    variant="contained"
                  >
                    {t('JOIN')}
                  </Button>
                </Box>
              </Box>
            )}
          </Card>
        </Box>
      </Container>
    </Box>
  )
}
