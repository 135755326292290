import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './en.json'
import es from './es.json'
import cz from './cz.json'
import de from './de.json'
import fr from './fr.json'
import hu from './hu.json'
import id from './id.json'
import jp from './jp.json'
import ru from './ru.json'
import { indonesiaDomains } from '../components/store'

export const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  cz: {
    translation: cz,
  },
  de: {
    translation: de,
  },
  fr: {
    translation: fr,
  },
  hu: {
    translation: hu,
  },
  id: {
    translation: id,
  },
  jp: {
    translation: jp,
  },
  ru: {
    translation: ru,
  },
} as const

const getDefaultLang = () => {
  if (indonesiaDomains.includes(window.location.hostname)) {
    return 'id'
  }

  return 'en'
}

i18n.use(initReactI18next).init({
  lng: getDefaultLang(),
  resources,
})
