/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react'
import {
  Alert,
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import qs from 'qs'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import MaskedInput from 'react-text-mask'
import { store } from '../components/store'
import { Countries } from '../lib/countries'
import { arrayOfStates } from '../lib/utilities'

declare global {
  interface Window {
    Xendit: any
  }
}

export default function Payment() {
  const { dispatch, state } = useContext(store)
  const { t } = useTranslation()
  const theme = useTheme()
  const [xenditToken, setxenditToken] = useState<any>(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState<boolean>(false)

  const getCountOfDigits = (str: string) => {
    return str.replace(/[^0-9]/g, '').length
  }

  const validatePayment = () => {
    const {
      ccNumber,
      ccCVV,
      ccExpMonth,
      ccExpYear,
      country,
      email,
      phone,
      firstName,
      lastName,
      company,
      addressLine1,
      city,
      stateProvince,
      postalCode,
    } = state.payment

    return new Promise((resolve, reject): void => {
      if (
        !ccNumber ||
        !ccCVV ||
        !ccExpMonth ||
        !ccExpYear ||
        !country ||
        !email ||
        !phone ||
        !firstName ||
        !lastName ||
        !addressLine1 ||
        !city ||
        !stateProvince ||
        !postalCode
      ) {
        reject(new Error('Missing required field'))
      }

      if (
        country === 'US' &&
        !arrayOfStates.includes(stateProvince.toUpperCase())
      ) {
        reject(new Error('Invalid state or province'))
      }

      if (state.isIndonesia && getCountOfDigits(phone) < 10) {
        reject(new Error('Phone number must be a minimum of 10 digits'))
      }

      if (
        !window.Xendit.card.validateExpiry(
          ccExpMonth,
          `${new Date().getFullYear()}`.slice(0, 2) + ccExpYear
        )
      ) {
        reject(new Error('Invalid card expiration'))
      }

      resolve(null)
    })
  }

  interface XenditToken {
    payer_authentication_url?: string
    status: string
    id: string
  }

  const completePayment = async (token: XenditToken | null = xenditToken) => {
    setLoading(true)

    try {
      const payload: any = {
        user_id: `${state.memberId}`,
        country: state.country,
        address_line_1: state.payment.addressLine1,
        cc_number: state.payment.ccNumber,
        cc_exp: `${state.payment.ccExpMonth}/${state.payment.ccExpYear}`,
        cvv: state.payment.ccCVV,
        city: state.payment.city,
        state_province: state.payment.stateProvince,
        postal_code: state.payment.postalCode,
        order_id: `${state.order.orderId}`,
        amount: state.order.amount + state.order.tax,
        tax: state.order.tax,
        provider: 'verifi',
        first_name: state.payment.firstName,
        last_name: state.payment.lastName,
        ip_address: '127.0.0.1',
        merchant_defined_field_1: `${state.memberId}`,
        merchant_defined_field_3: 'talkfusion',
        email: state.payment.email,
      }

      if (token && token.id) {
        // Set amount and tax as IDR
        payload.provider = 'xendit'
        payload.amount = Math.ceil(state.order.idrAmount + state.order.idrTax)
        payload.tax = state.order.idrTax
        payload.xendit_token = token.id
      }

      interface PaymentResponse {
        provider: string
        token: string
      }

      let xendit_token = null
      let verifi_token = null

      // Submit payment to our payment server
      const { data: paymentResponse }: { data: PaymentResponse } = await axios({
        url: process.env.REACT_APP_PAYMENT_SERVER_URL,
        method: 'POST',
        data: payload,
      })

      // Get Verifi Token to send to Jonathan's server, this is for Xendit payments only
      if (paymentResponse.provider !== 'verifi') {
        xendit_token = paymentResponse.token
        const { data: verifiToken } = await axios({
          url: `${process.env.REACT_APP_PAYMENT_SERVER_URL}/verifi/tokenize`,
          method: 'POST',
          data: payload,
        })

        verifi_token = verifiToken.token
      } else {
        verifi_token = paymentResponse.token
      }

      // Then submit payment success payload to Jonathan's server to update the order,
      // it will return a session token to redirect to a welcome page
      const { data: sessionId }: any = await axios({
        url: 'https://signup.talkfusion.com/order/join/x_orderProcess_b.asp',
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
        data: qs.stringify({
          orderId: state.order.orderId,
          verifi_token,
          xendit_token,
        }),
      })

      // Get session token and redirect to welcome page
      if (sessionId) {
        window.location.href = `https://signup.talkfusion.com/join/_appWelcome2022.asp?sessionId=${sessionId}`
      } else {
        throw new Error('Failed to redirect to welcome page.')
      }
    } catch (err: any) {
      console.log(err)
      if (err.response && err.response.data?.message?.includes('incomplete')) {
        setErrorMessage('Card authentication failed.')
      } else if (
        err.response &&
        err.response.data?.message === 'INSUFFICIENT_BALANCE'
      ) {
        setErrorMessage('There is insufficient balance on the provided card.')
      } else if (
        err.response &&
        err.response.data?.message === 'EXPIRED_CARD'
      ) {
        setErrorMessage('The provided card is expired.')
      } else if (err.response && err.response.data?.message === 'INVALID_CVN') {
        setErrorMessage('The provided CVN for the card is invalid.')
      } else if (
        err.response &&
        err.response.data?.message === 'CARD_DECLINED'
      ) {
        setErrorMessage('This card has been declined.')
      } else {
        setErrorMessage(err.message)
      }
      setLoading(false)
    }
  }

  const xenditResponseHandler = async (err: any, token: XenditToken) => {
    if (err) {
      console.log(err)
      setLoading(false)
      setErrorMessage(
        'Card authentication failed. Please try again or use another card.'
      )
      return
    }

    dispatch({
      type: 'SET_PAYMENT',
      payload: { xenditToken: token.id },
    })

    setxenditToken(token)

    if (token.status === 'VERIFIED') {
      completePayment(token)
    }
  }

  const getXenditToken = () => {
    const createTokenPayload = {
      amount: Math.ceil(state.order.idrAmount + state.order.idrTax),
      card_number: state.payment.ccNumber,
      card_exp_month: state.payment.ccExpMonth,
      card_exp_year: `${new Date().getFullYear().toString().substring(2, 0)}${
        state.payment.ccExpYear
      }`,
      card_cvn: state.payment.ccCVV,
      is_multiple_use: false,
      should_authenticate: true,
      billing_details: {
        given_names: state.payment.firstName,
        surname: state.payment.lastName,
        email: state.payment.email,
        mobile_number: state.payment.phone,
        phone_number: state.payment.phone,
        address: {
          country: state.payment.country,
          street_line1: state.payment.addressLine1,
          street_line2: state.payment.addressLine2,
          city: state.payment.city,
          province_state: state.payment.stateProvince,
          postal_code: state.payment.postalCode,
        },
      },
    }

    window.Xendit.card.createToken(createTokenPayload, xenditResponseHandler)
  }

  const open3DVerificationWindow = () => {
    const params = 'popup=yes,scrollbars=no,status=no,width=400,height=500'
    const verificationWindow = window.open(
      xenditToken.payer_authentication_url,
      '3dauthframe',
      params
    )

    if (verificationWindow) {
      const authWindowInterval = setInterval(() => {
        if (verificationWindow.closed) {
          clearInterval(authWindowInterval)
          completePayment()
        }
      }, 1000)
    }
  }

  useEffect(() => {
    if (
      xenditToken &&
      xenditToken.status === 'IN_REVIEW' &&
      xenditToken.payer_authentication_url
    ) {
      open3DVerificationWindow()
    } else if (xenditToken && xenditToken.status === 'FAILED') {
      setLoading(false)
      setErrorMessage(
        'Card authentication failed. Please try again or use another card.'
      )
    }
  }, [xenditToken])

  const onSubmit = async () => {
    setLoading(true)
    setErrorMessage('')

    try {
      await validatePayment()

      if (state.isIndonesia) {
        // Set the xendit token, depending on the status,
        // we show the verification dialog or just continue payment
        getXenditToken()
      } else {
        completePayment()
      }
    } catch (err: any) {
      if (
        err.error_code === 'BRAND_NOT_SUPPORTED_ERROR' ||
        err.error_code === 'API_VALIDATION_ERROR'
      ) {
        setLoading(false)
        setErrorMessage(
          err.message ||
            'There was an error processing payment with the specified card.'
        )
      } else {
        setErrorMessage(
          err.message ||
            'There was an error processing payment with the specified card.'
        )

        if (
          err.message.includes('Missing') ||
          err.message.includes('Invalid') ||
          err.status === 'FAILED'
        ) {
          setLoading(false)
        }
      }
    }
  }

  const handleCountryChange = async (e: any) => {
    await dispatch({
      type: 'SET_PAYMENT',
      payload: { country: e.target.value },
    })
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.grey[100],
      }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          py: 8,
        }}
      >
        <Card sx={{ mb: 2 }}>
          <CardContent sx={{ p: 4 }}>
            <Grid container spacing={12}>
              <Grid item xs={12} lg={3}>
                <Typography>ACCOUNT INFORMATION</Typography>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Typography>REVIEW PURCHASE</Typography>
              </Grid>
              <Grid item xs={12} lg={3} sx={{ borderBottom: '1px solid red' }}>
                <Typography>PAYMENT</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              sx={{ paddingTop: '2rem', paddingBottom: '2rem' }}
            >
              <Grid item xs={12}>
                <Typography variant="h4">Purchase Overview</Typography>
              </Grid>
            </Grid>
            {errorMessage && errorMessage !== 'Network Error' && (
              <Alert severity="error" sx={{ mb: 3 }}>
                {errorMessage}
              </Alert>
            )}
            {errorMessage && errorMessage === 'Network Error' && (
              <Alert severity="success" sx={{ mb: 3 }}>
                {
                  'Your order has been processed and your card has been successfully charged.  If you do not receive a Welcome Email with your member ID please contact support@talkfusion.com.'
                }
                {state.isIndonesia &&
                  'Pesanan Anda telah diproses dan kartu Anda telah berhasil ditagih. Jika Anda tidak menerima Email Selamat Datang dengan ID anggota Anda, silakan hubungi support@talkfusion.com.'}
              </Alert>
            )}
            {xenditToken && xenditToken.payer_authentication_url && (
              <Alert severity="warning" sx={{ mb: 3 }}>
                Your payment requires additional authentication. A pop-up window
                should open automatically. Please follow the on-screen
                instructions to authenticate your payment. If you do not see a
                pop-up window, please check your browser settings to enable them
                for this page. The window does not close automatically, please
                close the window after authentication is complete and the
                payment will proceed automatically.
              </Alert>
            )}
            {state.isIndonesia &&
              xenditToken &&
              xenditToken.payer_authentication_url && (
                <Alert severity="warning" sx={{ mb: 3 }}>
                  Pembayaran Anda memerlukan autentikasi tambahan. Jendela
                  pop-up akan terbuka secara otomatis. Harap ikuti petunjuk di
                  layar untuk mengautentikasi pembayaran Anda. Jika Anda tidak
                  melihat jendela pop-up, harap periksa pengaturan browser Anda
                  untuk mengaktifkannya di halaman ini. Jendela tidak menutup
                  secara otomatis, harap tutup jendela setelah autentikasi
                  selesai dan pembayaran akan diproses secara otomatis.
                </Alert>
              )}
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t('FIRST_NAME')}
                  required
                  defaultValue={state.payment.firstName}
                  onBlur={(e) =>
                    dispatch({
                      type: 'SET_PAYMENT',
                      payload: { firstName: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t('LAST_NAME')}
                  required
                  defaultValue={state.payment.lastName}
                  onBlur={(e) =>
                    dispatch({
                      type: 'SET_PAYMENT',
                      payload: { lastName: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t('FORM_COMPANY_NAME')}
                  defaultValue={state.payment.company}
                  onBlur={(e) =>
                    dispatch({
                      type: 'SET_PAYMENT',
                      payload: { company: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Billing Address"
                  required
                  defaultValue={state.payment.addressLine1}
                  onBlur={(e) =>
                    dispatch({
                      type: 'SET_PAYMENT',
                      payload: { addressLine1: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label={t('FORM_CITY')}
                  required
                  defaultValue={state.payment.city}
                  onBlur={(e) =>
                    dispatch({
                      type: 'SET_PAYMENT',
                      payload: { city: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label={t('FORM_STATE_PROVINCE')}
                  helperText="e.g. AR, TX, NY..."
                  defaultValue={state.payment.stateProvince}
                  onBlur={(e) =>
                    dispatch({
                      type: 'SET_PAYMENT',
                      payload: { stateProvince: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label={t('FORM_POSTAL_CODE')}
                  defaultValue={state.payment.postalCode}
                  onBlur={(e) =>
                    dispatch({
                      type: 'SET_PAYMENT',
                      payload: { postalCode: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  value={state.payment.country}
                  fullWidth
                  label={t('COUNTRY')}
                  onChange={handleCountryChange}
                >
                  {Countries.map((c) => (
                    <MenuItem value={c.value} key={c.name}>
                      {c.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t('FORM_RESIDENT_PHONE')}
                  helperText="#-###-###-####"
                  defaultValue={state.payment.phone}
                  onBlur={(e) =>
                    dispatch({
                      type: 'SET_PAYMENT',
                      payload: { phone: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('EMAIL')}
                  required
                  defaultValue={state.payment.email}
                  onBlur={(e) =>
                    dispatch({
                      type: 'SET_PAYMENT',
                      payload: { email: e.target.value },
                    })
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <MaskedInput
                  mask={[
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                  ]}
                  defaultValue={state.payment.ccNumber}
                  onBlur={(e) =>
                    dispatch({
                      type: 'SET_PAYMENT',
                      payload: { ccNumber: e.target.value },
                    })
                  }
                  render={(ref, props) => (
                    <TextField
                      inputRef={ref}
                      fullWidth
                      label="Credit Card Number"
                      required
                      {...props}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <MaskedInput
                  mask={[/[0-9]/, /[0-9]/, /[0-9]/]}
                  defaultValue={state.payment.ccCVV}
                  onBlur={(e) =>
                    dispatch({
                      type: 'SET_PAYMENT',
                      payload: { ccCVV: e.target.value },
                    })
                  }
                  render={(ref, props) => (
                    <TextField
                      inputRef={ref}
                      fullWidth
                      label="CVV2"
                      required
                      {...props}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <MaskedInput
                  mask={[/[0-9]/, /[0-9]/]}
                  defaultValue={state.payment.ccExpMonth}
                  onBlur={(e) =>
                    dispatch({
                      type: 'SET_PAYMENT',
                      payload: { ccExpMonth: e.target.value },
                    })
                  }
                  render={(ref, props) => (
                    <TextField
                      inputRef={ref}
                      fullWidth
                      label="Exp Date mm"
                      required
                      {...props}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <MaskedInput
                  mask={[/[0-9]/, /[0-9]/]}
                  defaultValue={state.payment.ccExpYear}
                  onBlur={(e) =>
                    dispatch({
                      type: 'SET_PAYMENT',
                      payload: { ccExpYear: e.target.value },
                    })
                  }
                  render={(ref, props) => (
                    <TextField
                      inputRef={ref}
                      fullWidth
                      required
                      label="Exp Date yy"
                      {...props}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ paddingTop: '1rem' }} justifyContent="center">
              <Typography align="center" mt={2}>
                PLEASE NOTE: Talk Fusion has captured your IP Address with this
                purchase. For your protection, we track down all fraudulent
                activity and help local authorities prosecute.
              </Typography>
            </Grid>
            <Grid container sx={{ paddingTop: '1rem' }} justifyContent="center">
              <img src={`/images/common/cc-standin.png`} />
            </Grid>
            <Grid container sx={{ paddingTop: '1rem' }} justifyContent="center">
              <LoadingButton
                variant="contained"
                loading={loading}
                size="large"
                onClick={onSubmit}
              >
                {t('SUBMIT')}
              </LoadingButton>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Box>
  )
}
