import React, { useState } from 'react'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Snackbar,
  Typography,
  TextField,
} from '@mui/material'
import { Countries } from '../../lib/countries'
import { useTranslation } from 'react-i18next'
import axios from '../../lib/axios'

export default function NoSponsorModal(props: {
  open: boolean
  close: Function
}) {
  const { t } = useTranslation()
  const [notification, setNotification] = useState(false)
  const [isError, setIsError] = useState(false)
  const [message, setMessage] = useState('')
  const [info, setInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    country: '',
    phone: '',
  })

  const handleNoSponsorFormSubmit = async () => {
    setIsError(false)
    try {
      await axios.post(
        'https://api.talkfusion-cloud.com/sign-up/no-sponsor-id',
        {
          first_name: info.firstName,
          last_name: info.lastName,
          email: info.email,
          country: info.country,
          fd12: info.phone,
          is_associate: false,
          language: 'en',
        }
      )
      setMessage(
        'Your request has been sent and we will get back with you shortly'
      )
    } catch {
      setIsError(true)
      setMessage('An error has ocurred. Please try again later')
    } finally {
      setNotification(true)
      await new Promise((resolve) => setTimeout(resolve, 1500))
      props.close()
    }
  }

  const handleInputChange = (val: any) => {
    setInfo({ ...info, ...val })
  }

  return (
    <Dialog open={props.open}>
      <Snackbar
        open={notification}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setNotification(false)}
      >
        <Alert severity={isError ? 'error' : 'success'}>
          <Typography>{message}</Typography>
        </Alert>
      </Snackbar>
      <DialogTitle>{t('SIGNUP_NOSPONSOR_DIALOG_TITLE')}</DialogTitle>
      <DialogContent>
        <Typography>{t('SIGNUP_NOSPONSOR_DIALOG_BODY')}</Typography>
        <Grid container spacing={2} sx={{ pt: 4 }}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={t('FIRST_NAME')}
              size="small"
              onBlur={({ target: { value } }) =>
                handleInputChange({ firstName: value })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={t('LAST_NAME')}
              size="small"
              onBlur={({ target: { value } }) =>
                handleInputChange({ lastName: value })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={t('EMAIL')}
              size="small"
              onBlur={({ target: { value } }) =>
                handleInputChange({ email: value })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              select
              defaultValue=""
              fullWidth
              label={t('COUNTRY')}
              size="small"
              onBlur={({ target: { value } }) =>
                handleInputChange({ country: value })
              }
            >
              {Countries.map((country) => {
                return (
                  <MenuItem value={country.value} key={country.name}>
                    {country.name}
                  </MenuItem>
                )
              })}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={t('PHONE_NUMBER')}
              size="small"
              onBlur={({ target: { value } }) =>
                handleInputChange({ phone: value })
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => props.close()}>
          {t('CANCEL')}
        </Button>
        <Button variant="contained" onClick={handleNoSponsorFormSubmit}>
          {t('SEND')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
