import React, { useContext, useEffect } from 'react'
import Sponsorship from './steps/Sponsorship'
import PackageSelection from './steps/PackageSelection'
import Registration from './steps/Registration'
import { store } from '../components/store'

export default function Signup() {
  const { dispatch, state } = useContext(store)

  useEffect(() => {
    dispatch({ type: 'SET_INDONESIA_DOMAIN' })
  }, [])

  return (
    <>
      <Sponsorship />
      {state.country && state.registration.program !== null && (
        <PackageSelection />
      )}
      {state.registration.planId !== '' && <Registration />}
    </>
  )
}
