import React, { createContext, useReducer } from 'react'
import { getPackageInfoFromId, getSalesTax } from '../lib/utilities'

export const indonesiaDomains = ['lvh.me', 'jointalkfusion.id']

const initialState: any = {
  sponsor: null,
  placementId: null,
  placement: 'right',
  country: '',
  indonesiaDomain: false,
  isIndonesia: false,
  isTFAPP: false,
  memberId: '',
  payment: {
    ccNumber: '',
    ccCVV: '',
    ccExpMonth: '',
    ccExpYear: '',
    country: '',
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    company: '',
    addressLine1: '',
    city: '',
    stateProvince: '',
    postalCode: '',
    xenditToken: '',
  },
  order: {
    orderId: '',
    amount: 0,
    tax: 0,
    idrAmount: 0,
    idrTax: 0,
    calculatedAmount: 0,
    calculatedTax: 0,
  },
  registration: {
    planId: '',
    packageId: '',
    program: 1, // 0 = customer, 1 = associate
    email: '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',
    phone: '',
    birthDate: {
      dateTime: null,
      text: '',
    },
    firstName: '',
    lastName: '',
    company: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    stateProvince: '',
    postalCode: '',
    npwp: '',
    nik: '',
  },
  packageInfo: {
    id: 0,
    name: '',
    fee: 0,
    monthlyPlan: 0,
    qty: 1,
    bv: 0,
  },
  prePayDiscount: 0,
}

export const store = createContext(initialState)

const { Provider } = store

export const StateProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer((state: any, action: any) => {
    const currentState = { ...state }

    switch (action.type) {
      case 'SET_INDONESIA_DOMAIN':
        if (indonesiaDomains.includes(window.location.hostname)) {
          currentState.indonesiaDomain = true
          currentState.isIndonesia = true
        }

        return currentState
      case 'SET_SPONSOR':
        currentState.sponsor = action.payload
        return currentState
      case 'SET_MEMBER_ID':
        currentState.memberId = action.payload
        return currentState
      case 'SET_PLACEMENT':
        currentState.placement = action.payload
        return currentState
      case 'SET_PLACEMENT_ID':
        currentState.placementId = action.payload
        return currentState
      case 'SET_COUNTRY':
        currentState.country = action.payload
        // Reset the registration form
        currentState.registration = { ...initialState.registration }

        if (action.payload === 'ID') {
          currentState.isIndonesia = true
        } else if (!currentState.indonesiaDomain) {
          currentState.isIndonesia = false
        }
        return currentState
      case 'SET_PLAN_ID':
        currentState.registration.planId = action.payload
        currentState.registration.packageId = action.payload
        currentState.packageInfo = getPackageInfoFromId(action.payload)

        if (
          (currentState.indonesiaDomain || currentState.country === 'ID') &&
          action.payload > 0
        ) {
          currentState.packageInfo.monthlyPlan =
            currentState.packageInfo.indonesiaMonthlyPlan
        }

        return currentState
      case 'SET_PROGRAM_TYPE': {
        let type = action.payload

        if (typeof action.payload === 'string' && /\d/.test(action.payload)) {
          type = parseInt(action.payload, 10)
        }

        currentState.registration.program = type
        return currentState
      }
      case 'SET_PREPAY_DISCOUNT': {
        let prePayMonths = 1
        const packageItem = getPackageInfoFromId(
          currentState.registration.packageId
        )

        const monthlyPlan =
          currentState.indonesiaDomain || currentState.country === 'ID'
            ? packageItem.indonesiaMonthlyPlan || packageItem.monthlyPlan
            : packageItem.monthlyPlan

        switch (action.payload) {
          case 10:
            prePayMonths = 12
            break
          case 15:
            prePayMonths = 36
            break
          case 20:
            prePayMonths = 60
            break
        }

        currentState.prePayDiscount = action.payload

        if (action.payload > 0) {
          const prePayTotal = monthlyPlan * prePayMonths

          if (currentState.indonesiaDomain || currentState.country === 'ID') {
            currentState.packageInfo.monthlyPlan =
              currentState.packageInfo.indonesiaMonthlyPlan
            currentState.packageInfo.indonesiaMonthlyPlan =
              prePayTotal - prePayTotal * (action.payload / 100)
          }

          currentState.packageInfo.monthlyPlan =
            prePayTotal - prePayTotal * (action.payload / 100)

          currentState.packageInfo.qty = prePayMonths
        } else if (action.payload === 0 || action.payload === null) {
          currentState.packageInfo = getPackageInfoFromId(
            currentState.registration.packageId
          )

          if (currentState.indonesiaDomain || currentState.country === 'ID') {
            currentState.packageInfo.monthlyPlan =
              currentState.packageInfo.indonesiaMonthlyPlan
          }
        }

        return currentState
      }
      case 'SET_TAX_AMOUNT':
        if (action.payload) {
          currentState.order.calculatedTax = action.payload
        }

        return currentState
      case 'SET_PAYMENT':
        currentState.payment = {
          ...currentState.payment,
          ...action.payload,
        }
        return currentState
      case 'SET_ORDER':
        currentState.order = {
          ...currentState.order,
          orderId: action.payload.orderId,
          // These amounts came from Jonathan's endpoint as IDR
          amount: action.payload.amount,
          tax: action.payload.tax,
          idrAmount: action.payload.idrAmount,
          idrTax: action.payload.idrTax,
          // These are calculated from front-end, in USD
          calculatedAmount:
            (currentState.indonesiaDomain &&
            currentState.country === 'ID' &&
            currentState.packageInfo.indonesiaFee
              ? currentState.packageInfo.indonesiaFee ||
                currentState.packageInfo.fee
              : currentState.packageInfo.fee) +
            (currentState.indonesiaDomain &&
            currentState.country === 'ID' &&
            state.registration.program === 1
              ? 10
              : 0),
        }
        return currentState
      case 'SET_REGISTRATION':
        currentState.registration = {
          ...currentState.registration,
          ...action.payload,
        }
        return currentState
      case 'SET_TFAPP':
        currentState.isTFAPP = action.payload
        return currentState
      case 'COPY_REGISTRATION_TO_PAYMENT':
        currentState.payment = {
          ...currentState.payment,
          email: currentState.registration.email,
          firstName: currentState.registration.firstName,
          lastName: currentState.registration.lastName,
          phone: currentState.registration.phone,
          addressLine1: `${currentState.registration.addressLine1}${
            currentState.registration.addressLine2
              ? ` ${currentState.registration.addressLine2}`
              : ''
          }`,
          country: currentState.country,
          city: currentState.registration.city,
          stateProvince: currentState.registration.stateProvince,
          postalCode: currentState.registration.postalCode,
          company: currentState.registration.company,
        }
        return currentState
      case 'CLEAR_PROGRAM_TYPE':
        currentState.registration.program = null
        return currentState
      default:
        throw new Error()
    }
  }, initialState)

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}
